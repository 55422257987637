/* eslint-disable prettier/prettier */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

import logoDefault from '../assets/images/logo.png';

class Header extends Component {
  render() {
    const { headerType } = this.props;

    return (
      <header className={headerType}>
        <div className="container">
          <div className="row">
            <div className="col-12 sm:col-3">
              <AniLink
                paintDrip
                hex="#001853"
                to="/"
                aria-label="home"
                className="logo"
              >
                {headerType ? (
                  <div>
                    <img
                      src={logoDefault}
                      alt="Otago Painting Solutions logo"
                      width="209"
                      className="home-logo"
                    />
                    <img
                      src={logoDefault}
                      className="mobile-logo"
                      alt="Otago Painting Solutions logo"
                      width="209"
                    />
                  </div>
                ) : (
                  <img
                    src={logoDefault}
                    alt="Otago Painting Solutions logo"
                    width="209"
                  />
                )}
              </AniLink>
            </div>
            <div className="col-12 sm:col-6 lg-items">
              <nav className="nav">
                <ul className="nav-list">
                  <li className="nav-item">
                    <AniLink fade to="/services" activeClassName="current">
                      Services
                    </AniLink>
                  </li>
                  <li className="nav-item">
                    <AniLink fade to="/projects" activeClassName="current">
                      Projects
                    </AniLink>
                  </li>
                  <li className="nav-item">
                    <AniLink fade to="/about" activeClassName="current">
                      About
                    </AniLink>
                  </li>
                  <li className="nav-item">
                    <AniLink fade to="/contact" activeClassName="current">
                      Contact
                    </AniLink>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="col-12 sm:col-3 lg-items">
              <div className="contact-holder">
                <p>
                  Get in touch —
                  <a className="phone-link" href="tel: (03) 453 0886">
                    (03) 453 0886
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  headerType: PropTypes.string.isRequired,
};

export default Header;
