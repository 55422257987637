/* eslint-disable template-curly-spacing */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Fade } from 'react-reveal';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import GoogleMap from '../google-map';
import projectImage from '../../assets/images/contact-form-2.jpg';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Enter your email address'),
  name: Yup.string(),
  company: Yup.string(),
  phone: Yup.number()
    .positive()
    .integer(),
  message: Yup.string(),
});

const encode = data => {
  return Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
};

class ContactForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      name: '',
      company: '',
      email: '',
      phone: '',
      message: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  async handleSubmit(values, actions) {
    this.setState({
      loading: true,
    });

    const axiosConfig = {
      header: { 'Content-Type': 'application/x-www-form-urlencoded' },
    };

    try {
      await axios
        .post(
          '/',
          encode({ 'form-name': 'contact', ...this.state }),
          axiosConfig
        )
        .then(() => {
          this.setState({ submitted: true, loading: false });
          if (actions && typeof actions.setSubmitting === 'function') {
            actions.setSubmitting(false);
          }
          document.querySelector('.form-success').classList.add('success');
          document.querySelector('.form-intro').classList.add('hidden');
          document.querySelector('.form-wrapper').classList.add('submitted');
        })
        .catch(() => {
          this.setState({ submitted: false, loading: false });
        });
    } catch (e) {
      throw new Error(e.message);
    }
  }

  render() {
    const {
      submitted,
      loading,
      name,
      company,
      email,
      phone,
      message,
    } = this.state;

    const { heading, subheading, theme, map } = this.props;

    return (
      <Fade>
        <div
          className={`container-fluid fill-x form-container contact-form contact-form--${theme}`}
        >
          <div className="row">
            {map ? (
              <div className="col-12 md:col-6 contact-form--map">
                <GoogleMap />
                <div className="address">
                  <h3>Get in Touch!</h3>
                  <p>
                    Otago Painting Solutions Limited
                    <br />
                    151 Kaikorai Valley Road
                    <br />
                    Dunedin 9011
                  </p>
                  <p className="m0">
                    p:
                    <a href="tel: (03) 453 0886" className="link">
                      (03) 453 0886
                    </a>
                  </p>
                  <p className="m0">
                    e:
                    <a
                      href="mailto:estimator@otagopaintingsolutions.co.nz"
                      className="link"
                    >
                      estimator@otagopaintingsolutions.co.nz
                    </a>
                  </p>
                </div>
              </div>
            ) : (
              <div className="col-12 md:col-4 contact-form--image">
                <img src={projectImage} alt="Otago Painting Solutions" />
              </div>
            )}
            <div
              className={`form-background col-12 ${
                map ? 'md:col-6' : 'md:col-8 lg:col-7'
              }`}
            >
              <div className="form-wrapper">
                <div className="form-intro">
                  <h4 className="form-title">{heading}</h4>
                  <p className="form-subtitle">{subheading}</p>
                </div>
                {!submitted && (
                  <Formik
                    initialValues={{
                      email: '',
                      name: '',
                      phone: '',
                      company: '',
                      message: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, actions) =>
                      this.handleSubmit(values, actions)
                    }
                    render={() => (
                      <Form
                        name="contact"
                        method="post"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                        autoComplete="off"
                      >
                        <input type="hidden" name="form-name" value="contact" />
                        <input type="hidden" name="bot-field" />
                        <div className="field-wrapper">
                          <div className="field field-name">
                            <ErrorMessage
                              name="name"
                              component="span"
                              className="field-error"
                            />
                            <Field
                              aria-label="your name"
                              aria-required="true"
                              name="name"
                              placeholder="Your name"
                              type="text"
                              className="form-input"
                              value={name}
                              onChange={this.handleChange}
                              required
                            />
                          </div>

                          <div className="field">
                            <ErrorMessage
                              name="company"
                              component="span"
                              className="field-error"
                            />
                            <Field
                              aria-label="your company"
                              aria-required="false"
                              name="company"
                              type="text"
                              placeholder="Company"
                              className="form-input"
                              value={company}
                              onChange={this.handleChange}
                            />
                          </div>

                          <div className="field">
                            <ErrorMessage
                              name="phone"
                              component="span"
                              className="field-error"
                            />
                            <Field
                              aria-label="your phone number"
                              aria-required="true"
                              name="phone"
                              type="text"
                              placeholder="Phone Number"
                              className="form-input"
                              value={phone}
                              onChange={this.handleChange}
                            />
                          </div>

                          <div className="field field-email">
                            <ErrorMessage
                              name="email"
                              component="span"
                              className="field-error"
                            />
                            <Field
                              aria-label="your email address"
                              aria-required="true"
                              name="email"
                              type="email"
                              placeholder="Email Address"
                              className="form-input"
                              value={email}
                              onChange={this.handleChange}
                              required
                            />
                          </div>
                        </div>

                        <div className="message">
                          <div className="field">
                            <ErrorMessage
                              name="message"
                              component="span"
                              className="field-error"
                            />
                            <Field
                              component="textarea"
                              aria-label="type your request here"
                              aria-required="true"
                              name="message"
                              type="textarea"
                              placeholder="Type your request here"
                              rows="10"
                              value={message}
                              onChange={this.handleChange}
                              className="textarea-input"
                              resize="false"
                            />
                          </div>
                        </div>

                        <button
                          data-element="submit"
                          type="submit"
                          className="submit"
                        >
                          {!loading && 'Send message'}
                          {loading && 'Sending...'}
                        </button>
                      </Form>
                    )}
                  />
                )}
                {submitted && !loading && (
                  <div className="form-success">
                    <h4 className="form-title align-center">
                      Thanks for your enquiry.
                      <br />
                      We’ll be in touch soon
                    </h4>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Fade>
    );
  }
}

export default ContactForm;

ContactForm.defaultProps = {
  theme: 'grey',
  map: false,
};

ContactForm.propTypes = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  theme: PropTypes.string,
  map: PropTypes.bool,
};
