import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

import mapMarker from '../assets/icons/marker.png';

const MapSettings = ({ marker, text }) => (
  <div className="marker">
    <img src={marker} alt="Map marker" width="20" />
    <p>{text}</p>
  </div>
);

class GoogleMap extends Component {
  static defaultProps = {
    center: {
      lat: -45.8680035,
      lng: 170.487893,
    },
    zoom: 14.3,
  };

  render() {
    const { zoom, center } = this.props;

    return (
      <a
        href="https://goo.gl/maps/r8RLq5AXhHjh9esz5"
        target="_blank"
        rel="noopener noreferrer"
        className="db map-link"
      >
        <div className="map-holder">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyBNzPPMea-sIwH4clC4vEtW3MMdypvas4w',
            }}
            defaultCenter={center}
            defaultZoom={zoom}
            scrollWheel={false}
            draggable={false}
            resetBoundsOnResize
            options={{
              zoomControl: false,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
            }}
          >
            <MapSettings
              lat={-45.8691913}
              lng={170.4734651}
              marker={mapMarker}
              text="151 Kaikorai Valley Road"
              className="map-details"
            />
          </GoogleMapReact>
        </div>
      </a>
    );
  }
}

export default GoogleMap;
