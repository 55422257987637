/* eslint-disable prettier/prettier */
import { slide as Menu } from 'react-burger-menu';
import React, { Component } from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

class MobileNav extends Component {
  constructor(props) {
    super(props);

    this.removeIndex = this.removeIndex.bind(this);
  }

  componentDidMount() {
    this.removeIndex();
  }

  // eslint-disable-next-line class-methods-use-this
  removeIndex() {
    const noFocus = document.querySelectorAll('.no-focus');

    noFocus.forEach(el => {
      el.setAttribute('tabindex', '-1');
    });
  }

  render() {
    return (
      <div className="mobile-wrapper">
        <Menu
          right
          htmlClassName="nav-open"
          bodyClassName="nav-open"
          className="mobile-nav"
          onStateChange={this.removeIndex}
        >
          <AniLink fade to="/services" activeClassName="current">
            Services
          </AniLink>
          <br className="no-focus" />
          <AniLink fade to="/projects" activeClassName="current">
            Projects
          </AniLink>
          <br className="no-focus" />
          <AniLink fade to="/about" activeClassName="current">
            About
          </AniLink>
          <br className="no-focus" />
          <AniLink fade to="/contact" activeClassName="current">
            Contact
          </AniLink>
          <div className="contact-holder no-focus">
            <p>
              Get in touch —
              <a href="tel: (03) 453 0886">(03) 453 0886</a>
            </p>
          </div>
        </Menu>
      </div>
    );
  }
}

export default MobileNav;
