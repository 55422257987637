/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { withPrefix } from 'gatsby';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

import logo from '../assets/images/logo.png';
import sponsor from '../assets/images/highlanders-logo.png';
import sponsor2 from '../assets/images/sitewise-green.jpg';

const Footer = () => (
  <footer className="fill-x">
    <div className="container">
      <div className="row">
        <div className="col-12 sm:col-4 lg:col-3 logo-col">
          <div className="logo-holder">
            <AniLink paintDrip hex="#001853" to="/">
              <img src={logo} alt="Otago Painting Solutions" width="209" />
            </AniLink>
          </div>
        </div>
        <div className="col-12 sm:col-6 lg:col-4 lg:offset-1 contact-col">
          <p className="m0 bold blue social-heading">Contact us</p>
          <div className="contact-links">
            <a
              href="https://goo.gl/maps/YNpeWzDxNcB2"
              target="_blank"
              rel="noopener noreferrer"
              className="mb2"
            >
              <p className="address-link mb0 mt0">151 Kaikorai Valley Road</p>
              <p className="address-link mt0 mb0">Dunedin 9011</p>
            </a>
            <br />
            <a href="tel:03 453 0886">
              <p className="tel mb0 mt0">P 03 453 0886</p>
            </a>
            <br />
            <a href="mailto:estimator@otagopaintingsolutions.co.nz">
              <p className="m0 mt0">E estimator@otagopaintingsolutions.co.nz</p>
            </a>
          </div>
        </div>
        <div className="col-12 sm:col-2 lg:col-2 social-col">
          <p className="m0 bold blue social-heading">Follow us</p>
          <div className="social-links">
            <a
              href="https://www.facebook.com/otagopaintingsolutions"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="mb0 mt0">Facebook</p>
            </a>
            <br />
            <a
              href="https://nz.linkedin.com/company/otagopaintingsolutions"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="mb0 mt0">LinkedIn</p>
            </a>
            <br />
            <a
              href="https://www.instagram.com/otagopaintingsolutions"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="mb0 mt0">Instagram</p>
            </a>
          </div>
        </div>
        <div className="col-12 sm:col-12 lg:col-2 sponsor-col">
          <div className="sponsor-holder flex flex-column items-center">
            <img
              src={sponsor}
              alt="Official painting sponsor of the Highlanders"
              width="102"
            />
            <p className="ttu align-center">Offical painting sponsor</p>
            <img
              className="mt2"
              src={sponsor2}
              alt="sitewise sponsor"
              width="102"
            />
          </div>
        </div>
      </div>
    </div>
    <div className="copyright">
      <div className="container">
        <div className="row">
          <div className="col-6">
            <p className="bold white m0">
              &copy; {new Date().getFullYear()} Otago Painting Solutions
            </p>
          </div>
          <div className="col-6 align-right">
            <a
              href={withPrefix('/terms_of_trade.pdf')}
              className="white"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms & Conditions
            </a>
            <a
              href={withPrefix('/health_and_safety.pdf')}
              className="white m0 ml2"
              target="_blank"
              rel="noopener noreferrer"
            >
              Health & Safety
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
