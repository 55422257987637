import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

class PageBanner extends Component {
  constructor(props) {
    super(props);

    const { data } = this.props;
    const edges = data.allContentfulHeroBanner.edges[0];
    this.bannerImage =
      edges.node.items[Math.floor(Math.random() * edges.node.items.length)];
  }

  render() {
    return (
      <div className="page-banner-image">
        <img src={this.bannerImage.image.fluid.src} alt="page-banner" />
      </div>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulHeroBanner {
          edges {
            node {
              items {
                image {
                  id
                  description
                  fluid(quality: 100, maxWidth: 1600) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <PageBanner data={data} {...props} />}
  />
);

PageBanner.propTypes = {
  data: PropTypes.object.isRequired,
};
